import {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import usePostApi from './custom_hooks/usePostApi'
import {KTIcon} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import EditArticleFullPage from './EditArticleFP'
import Carousel from 'react-bootstrap/Carousel'
import {formatDateTime} from './helpers/DateFormatter'

function ViewArtcle({returnPath, data}: any) {
  const {id, art_id} = useParams()
  const {execute, loading} = usePostApi()
  const [articleData, setArtcleData]: any = useState({})

  const fetchData = async () => {
    try {
      const url = `/articles/get`
      const formData = {article_id: art_id}
      const response = await execute(url, 'POST', formData)
      setArtcleData(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const ImageViewBC = [
    {
      title: data?.title || '',
      path: returnPath,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Communities`,
      path: returnPath,
      isSeparator: true,
      isActive: false,
    },

    {
      title: `${articleData.title_en || articleData.title_ar || ''}`,
      path: `${returnPath}/${art_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const getVideoType = (url: string) => {
    if (!url) return
    // Check if the URL is a YouTube link (both short and long formats)
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
      )
      return videoId ? {type: 'youtube', id: videoId[1]} : null
    }

    // Check if the URL is an mp4 link
    if (url?.endsWith('.mp4')) {
      return {type: 'mp4', url}
    }

    return null
  }

  let video: any = getVideoType(articleData?.media_location_url)

  useEffect(() => {
    if (articleData?.media_location_url) {
      video = getVideoType(articleData?.media_location_url)
    }
  }, [articleData])

  const getMedia = () => {
    if (articleData.media_type === 'P') {
      // Render image
      return (
        <img
          src={articleData?.media_location_url}
          alt={articleData?.media_title_en || articleData?.media_title_ar}
          className='w-100 h-100 rounded'
          style={{maxHeight: '250px'}}
        />
      )
    } else if (articleData.media_type === 'A') {
      // Render audio
      return (
        <div className='d-flex justify-content-center'>
          <audio src={articleData.media_location_url} controls />
        </div>
      )
    } else if (articleData.media_type === 'V') {
      // Render video
      if (video?.type === 'youtube') {
        // YouTube video
        return (
          <iframe
            className='w-100 h-200px'
            src={`https://www.youtube.com/embed/${video.id}`}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        )
      } else if (video?.url) {
        // Other video types
        return (
          <video className='w-100' height='415' controls>
            <source src={video.url} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        )
      }
    } else if (articleData.media_type === 'G') {
      return (
        <div className='w-100'>
          <Carousel>
            {JSON.parse(articleData.media_info.media_info)?.map((image: string, index: number) => (
              <Carousel.Item key={`Slide ${index}`}>
                <img className='d-block w-100 h-200px rounded' src={image} alt={'Slider/Gallery'} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )
    }

    // Return null if no media type matches
    return null
  }

  const [isEdit, setIsEdit] = useState(false)

  const refreshData = (val: boolean | null) => {
    if (val === false) {
      setIsEdit(false)
      return
    }
    setIsEdit(false)
    fetchData()
  }

  if (loading) {
    return (
      <div className='card h-250px'>
        <div className='m-auto d-flex flex-column align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={ImageViewBC}>Article Details</PageTitle>
      {isEdit ? (
        <>
          <EditArticleFullPage
            returnPath={returnPath}
            type='edit'
            refreshData={refreshData}
            initData={articleData}
            secId={id}
          />
        </>
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center mb-5'>
            <h3 className='fw-normal'>
              {articleData.title_en} - {articleData.title_ar}
            </h3>
            <div>
              <Link to={returnPath} className='btn btn-light-primary btn-sm'>
                <KTIcon iconName='arrow-left' className='fs-2' />
                Back
              </Link>
              <button
                type='button'
                className='btn btn-primary btn-sm ms-4'
                onClick={() => setIsEdit(true)}
              >
                <KTIcon iconName='pencil' className='fs-2' />
                Edit
              </button>
            </div>
          </div>
          <div className='d-flex flex-column flex-md-row art-con'>
            <div className='w-100 w-md-75 card me-md-3 p-5'>
              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal mb-2 '>
                    Title (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.title_en}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Title (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.title_ar}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Title Phonetic (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.title_en_phonetic}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Title Phonetic (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.title_ar_phonetic}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Content (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.content_en}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Content (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.content_ar}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Content Phonetic (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.content_en_phonetic}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Content Phonetic (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.content_ar_phonetic}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Category Name (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.category_name_en}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Category Name (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.category_name_ar}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-5'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Subategory Name (English):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.sub_category_name_en || '-'}
                  </span>
                </div>

                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Subcategory Name (Arabic):
                  </label>
                  <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                    {articleData.sub_category_name_ar || '-'}
                  </span>
                </div>
              </div>

              <div className='d-flex flex-column flex-md-row mb-4'>
                <div className='w-100 w-md-50 me-md-2'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Tags Ar:
                  </label>
                  <div className='d-flex flex-wrap'>
                    {articleData?.tags_ar?.split(',').map((tag: string, index: number) => (
                      <p key={index} className='bg-secondary px-2 py-1 rounded me-2 mb-2'>
                        {tag}
                      </p>
                    ))}
                  </div>
                </div>
                <div className='w-100 w-md-50 ms-md-2 mt-5 mt-md-0'>
                  <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                    Tags En:
                  </label>
                  <div className='d-flex flex-wrap'>
                    {articleData?.tags_en && articleData.tags_en.split(',').length > 0
                      ? articleData.tags_en.split(',').map((tag: string, index: number) => (
                          <p key={index} className='bg-secondary px-2 py-1 rounded me-2 mb-2'>
                            {tag}
                          </p>
                        ))
                      : '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className='w-100 w-md-25 card ms-md-3 p-5'>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  Uploaded Media:
                </label>

                <div className='w-100'>{getMedia()}</div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  Start Date:
                </label>
                <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                  {formatDateTime(articleData.start_ts, 'datetime')}
                </span>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  End Date:
                </label>
                <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                  {formatDateTime(articleData.end_ts, 'datetime') || '--'}
                </span>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  Voice En:
                </label>
                <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                  {articleData.voice_info_en === null ? (
                    'TTS'
                  ) : (
                    <div className='d-flex flex-column justify-content-start'>
                      <div className='w-100'>
                        <div className='d-flex justify-content-center'>
                          <audio src={articleData?.voice_info_en?.media_location_url} controls />
                        </div>
                      </div>

                      <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                        {articleData?.voice_info_en?.media_title_en} -{' '}
                        {articleData?.voice_info_en?.media_title_ar}
                      </span>
                    </div>
                  )}
                </span>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  Voice Ar:
                </label>
                <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                  {articleData.voice_info_ar === null ? (
                    'TTS'
                  ) : (
                    <div className='d-flex flex-column justify-content-center'>
                      <div className='w-100'>
                        <div className='d-flex justify-content-center'>
                          <audio src={articleData?.voice_info_ar?.media_location_url} controls />
                        </div>
                      </div>

                      <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                        {articleData?.voice_info_ar?.media_title_ar} -{' '}
                        {articleData?.voice_info_en?.media_title_ar}
                      </span>
                    </div>
                  )}
                </span>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-normal form-label mb-2'>
                  Sort Rank:
                </label>
                <span className='fw-semibold fs-6 text-dark text-hover-primary default-cursor'>
                  {articleData.sort_rank}
                </span>
              </div>

              <div className='w-100'>
                <label className='fs-6 fw-normal form-label mb-2'>Status:</label>
                <span
                  className={`ms-3 fw-normal default-cursor badge badge-light-${
                    articleData.status === 1 ? 'success' : 'danger'
                  }`}
                >
                  {articleData.status === 1 ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewArtcle
