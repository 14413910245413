import {useState, useMemo, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {useIntl, FormattedMessage} from 'react-intl'
import AddNewAttachment from '../media/components/image/AddNewAttachment'

function GallerySelectModal({
  showModal,
  setShowModal,
  galleryData,
  fetchGalleryItems,
  handleGallerySelect,
  loading,
}: any) {
  const intl = useIntl()
  const [inputVal, setInputVal] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [showAddMediaModal, setShowAddMediaModal] = useState(false)

  const imagesPerPage = 18

  const filteredImages = useMemo(() => {
    return galleryData
      ? [...galleryData].filter((each: any) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, galleryData])

  const totalPages = Math.ceil(filteredImages.length / imagesPerPage) // Moved inside render function

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  )

  const [hoveredIndex, setHoveredIndex]: any = useState(null)
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  useEffect(() => {
    let interval: any
    if (hoveredIndex !== null) {
      interval = setInterval(() => {
        setActiveImageIndex((prevIndex) => {
          const sliderImgs = JSON.parse(galleryData[hoveredIndex].metadata)
          return (prevIndex + 1) % sliderImgs.length
        })
      }, 1000) // Change image every 1 second
    } else {
      setActiveImageIndex(0) // Reset when not hovered
    }

    return () => clearInterval(interval)
  }, [hoveredIndex, galleryData])

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName='custom-modal-img-select modal-dialog-centered'
      contentClassName='custom-modal-content-img-select'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <AddNewAttachment
          showModal={showAddMediaModal}
          setShowModal={setShowAddMediaModal}
          type='add'
          data={{}}
          loadData={fetchGalleryItems}
          setMediaId={(img: any) => {
            handleGallerySelect(img)
            setShowAddMediaModal(false)
          }}
        />

        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Select Image</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <div className='d-flex mb-5'>
          <input
            type='text'
            className='form-control form-control-solid me-2'
            placeholder='Search image'
            onChange={(e) => setInputVal(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-light-primary ms-2'
            onClick={() => setShowAddMediaModal(true)}
          >
            Upload
          </button>
        </div>

        {loading ? (
          <div className='h-150px d-flex justify-content-center align-items-center'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-wrap gap-3'>
            {paginatedImages.length > 0 ? (
              paginatedImages?.map((each, index) => {
                const metaData = JSON.parse(each.metadata)
                // const {media_ids} = metaData
                return (
                  <div
                    key={each.media_id}
                    className='image-con-width'
                    onClick={() => {
                      handleGallerySelect(each)
                      setShowModal(false)
                    }}
                  >
                    <div
                      className='image-container image-con-height'
                      style={{position: 'relative'}}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <img
                        src={hoveredIndex === index ? metaData[activeImageIndex] : metaData[0]}
                        alt={each.media_title_en || each.media_title_ar}
                        className='w-100 h-100 rounded'
                      />

                      <div className='overlay'></div>

                      {hoveredIndex === index && (
                        <div className='indicator-container'>
                          {metaData?.map((_: any, imgIndex: number) => (
                            <span
                              key={imgIndex}
                              className={`dot ${imgIndex === activeImageIndex ? 'active' : ''}`}
                            ></span>
                          ))}
                        </div>
                      )}
                    </div>
                    <span
                      className='d-inline-block text-truncate image-title'
                      style={{maxWidth: '100%'}}
                    >
                      {each.media_title_en || each.media_title_ar}
                    </span>
                  </div>
                )
              })
            ) : (
              <div className='d-flex justify-content-center w-100'>
                {inputVal === '' ? (
                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                ) : (
                  <FormattedMessage
                    id='MSG.NO_DATA_MATCH'
                    values={{
                      inputVal: <span className='text-primary'>{inputVal}</span>,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div className='d-flex justify-content-between align-items-center mt-10'>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className='btn btn-light btn-sm'
          >
            <KTIcon iconName='arrow-left' className='fs-3' />
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className='btn btn-light-primary btn-sm'
          >
            Next
            <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default GallerySelectModal
