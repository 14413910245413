import React, {useState} from 'react'
import TrackReqImg from './time-tracking.png'
import NoDataIcon from './job-interview.png'
import {KTIcon} from '../../../_metronic/helpers'
import {CreateCategory} from './CreateCategory'
import SubCategory from './SubCategory'
import usePageTitle from '../../modules/custom_hooks/usePageTitle'

function CategoryMain({props}: any) {
  usePageTitle('Category & Subcategory')
  const {categoryFetchData, categoryView, sectionId, sectionName} = props
  const [showModal, setShowModal] = useState(false)
  const [id, updateId]: any = useState('')

  const handleOpenModal = (category: any) => {
    updateId(category)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  return (
    <div>
      {categoryView.category_id === 0 ? (
        <div className='m-2 p-4'>
          <h3 className='fw-semibold fs-3'>Managing {sectionName} Section Page</h3>
          <p className='fw-normal'>
            Welcome to the {sectionName} Section Page! Here, you have the ability to create new
            categories, inactive existing ones, and modify category details as needed. We've
            designed this section to guide you through the process, ensuring that your categories
            are effectively managed and up to date.
          </p>
          <div className='row mt-6'>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={NoDataIcon}
                  className='track-req-icon mb-5'
                  style={{width: '55px'}}
                  alt='Add Friend'
                />
                <p className='text-center'>
                  Click on the '+ Create New Category' button to Create the Category.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img
                  src={TrackReqImg}
                  alt='Track Request'
                  className='track-req-icon mb-5'
                  style={{width: '55px'}}
                />
                <p className='text-center'>
                  Keep track of the status of your created categories. See what is active, and
                  inactive if needed.
                </p>
              </div>
            </div>
          </div>
          <div className='m-auto d-flex flex-column align-items-center'>
            <button
              className='btn btn-sm btn-light-primary fw-normal'
              onClick={() => handleOpenModal({sectionId})}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Create New Category
            </button>
          </div>
          <CreateCategory
            className='modal-backdrop'
            showModal={showModal}
            setShowModal={setShowModal}
            id={id}
            categoryFetchData={categoryFetchData}
            closeModal={handleCloseModal}
          />
        </div>
      ) : (
        <SubCategory props={categoryView} />
      )}
    </div>
  )
}

export default CategoryMain
